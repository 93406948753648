import React, { useState } from 'react'

// addPrompt is destructured from component arguments
function DisplayAdd({addPrompt,setCurrentPage}) {

    // controlled component state
    const [prompt, setPrompt] = useState("")

    return (
        <div>
            <form onSubmit={(e) => { addPrompt(prompt); e.preventDefault(); setCurrentPage('DISPLAY_LIST'); }}>
                <input
                    type="text"
                    autoFocus
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)} />
                <input type="submit" value="add" />
            </form>
            <button onClick={() => { setCurrentPage('DISPLAY_LIST') }}>cancel</button>
        </div>
    )
}

export default DisplayAdd