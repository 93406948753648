import React, { useEffect, useState } from 'react'
import './App.css'
import DisplayList from './DisplayList'
import DisplayAdd from './DisplayAdd'

function App() {

  // state variable representing the current page being displayed -- either DISPLAY_LIST or DISPLAY_ADD
  // note: this is using destructuring array into these [] items
  const [currentPage, setCurrentPage] = useState('DISPLAY_LIST')

  // state variable representing the list of prompts
  const [currentList, setCurrentList] = useState(JSON.parse(localStorage.getItem('prompts')) || [])

  function addPrompt(p) {
    setCurrentList([...currentList, p])
  }

  // hook in a function that gets called when currentPage changes
  useEffect(() => {
    console.log("currentPage:", currentPage)
  }, [currentPage])

  useEffect(() => {
    console.log("currentList: ", currentList)
    localStorage.setItem('prompts', JSON.stringify(currentList))
  }, [currentList])

  const pageToDisplay = currentPage === 'DISPLAY_LIST'
    ? <DisplayList currentList={currentList} setCurrentPage={setCurrentPage} />
    : <DisplayAdd addPrompt={addPrompt} setCurrentPage={setCurrentPage} />

  return (
    <div className="App">
      {pageToDisplay}
    </div>
  );
}

export default App
