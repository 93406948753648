import React from 'react'

function DisplayList({currentList,setCurrentPage}) {
    // render elements of currentList
    return (
        <div>
            <ul>
                {currentList.map(i => <li key={i}>{i}</li>)}
            </ul>
            <button>next</button>
            <button>use</button>
            <button>delete</button>
            <button onClick={() => { setCurrentPage('DISPLAY_ADD') }}>add</button>
        </div>
    )
}

export default DisplayList